// CkEditor
$( document ).ready(function() {
    if($("#agency_introduction").length){
        ClassicEditor
            .create(document.querySelector
                ('#agency_introduction'), {toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}
            )
            .catch(error => {
                console.log(error);
            });
    }

    if($("#agency_description").length){
        ClassicEditor
            .create(document.querySelector
                ('#agency_description'), {toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}
            )
            .catch(error => {
                console.log(error);
            });
    }

    if($("#domains_introduction").length){
        ClassicEditor
            .create(document.querySelector
                ('#domains_introduction'), {toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}
            )
            .catch(error => {
                console.log(error);
            });
    }

    if($("#domain_description").length){
        ClassicEditor
            .create(document.querySelector
                ('#domain_description'), {toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}
            )
            .catch(error => {
                console.log(error);
            });
    }

    if($("#prices_introduction").length){
        ClassicEditor
            .create(document.querySelector
                ('#prices_introduction'), {toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}
            )
            .catch(error => {
                console.log(error);
            });
    }

    if($("#price_details").length){
        ClassicEditor
            .create(document.querySelector
                ('#price_details'), {toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}
            )
            .catch(error => {
                console.log(error);
            });
    }

    if($("#legal_notice_description").length){
        ClassicEditor
            .create( document.querySelector
                ('#legal_notice_description' ), {toolbar: [ 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ]}
            )
            .catch( error => {
                console.log( error );
            } );
    }

    if($("#partners_introduction").length){
        ClassicEditor
            .create(document.querySelector
                ('#partners_introduction'), {toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']}
            )
            .catch(error => {
                console.log(error);
            });
    }
});